
import { Inject } from 'inversify-props';
import { Component, Vue } from 'vue-property-decorator';

import ClusterDiLiteService, { ClusterDiLiteServiceS } from '@/modules/cluster/cluster-di-lite.service';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import ClusterHotelsRatesModel from '@/modules/cluster/models/cluster-rates.model';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import DiLiteAllChannelsService, { DiLiteAllChannelsServiceS } from '@/modules/di-lite/di-lite-all-channels.service';

import HotelsList from '../../_common/hotels-list/index.vue';
import ListHeader from '../../_common/list-days-header.vue';
import DaysSet from './days-set.vue';

@Component({
    components: {
        HotelsList,
        ListHeader,
    },
})
export default class DiLiteHotelsList extends Vue {
    @Inject(ClusterDiLiteServiceS) private clusterDiLiteService!: ClusterDiLiteService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(ClusterServiceS) private clusterService!: ClusterService;
    @Inject(DiLiteAllChannelsServiceS) private diLiteAllChannelsService!: DiLiteAllChannelsService;

    get tableData() {
        const { hotels } = this.clusterDiLiteService;

        if (!hotels || !hotels.length) {
            return [];
        }

        return hotels.map(hotelData => ({
            table: [{
                value: hotelData.hotelName,
                onClick: () => this.toHotel(hotelData),
            }],
            component: {
                element: DaysSet,
                props: { hotelData },
            },
        }));
    }

    get headerData() {
        return [{
            value: 'hotelName',
            name: 'Hotel Name',
        }];
    }

    get component() {
        return {
            element: DaysSet,
            props: {},
        };
    }

    get pagesCount() {
        return this.clusterDiLiteService.hotelsCount;
    }

    get isLoading() {
        return this.clusterService.isLoading;
    }

    toHotel(hotelData: ClusterHotelsRatesModel) {
        const compsetId = hotelData.compsetMain
            ? hotelData.compsetMain.id
            : hotelData.compsets[0].id;

        const pos = hotelData.compsetMain
            ? hotelData.compsetMain.pos
            : hotelData.compsets[0].pos[0];

        this.documentFiltersService.compsetId = compsetId;
        this.documentFiltersService.compsetIdFromHighLevel = compsetId;
        this.documentFiltersService.settings.pos = pos;

        this.diLiteAllChannelsService.settings.priceType = this.clusterService.ratesSettings.priceType!;
        this.diLiteAllChannelsService.settings.roomTypeId = this.clusterService.ratesSettings.roomTypeId;
        this.diLiteAllChannelsService.settings.mealTypeId = this.clusterService.ratesSettings.mealTypeId;

        this.$router.push({
            name: `${this.$route.name!}.hotel`,
            params: {
                hotelId: String(hotelData.hotelId),
            },
        });
    }
}
