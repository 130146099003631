

import { Component, Mixins } from 'vue-property-decorator';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import DiLiteClusterHeader from '@/modules/cluster/components/di-lite/di-lite-cluster-header.vue';
import DiLiteClusterActions from '@/modules/cluster/components/di-lite/di-lite-cluster-actions.vue';
import ClusterDiLiteOtelMixin from '@/modules/open-telemetry/mixins/di-lite/di-lite-cluster-otel.mixin';
import DiLiteHotelsList from '@/modules/cluster/components/di-lite/di-lite-hotels-list/index.vue';
import ColorPickerGroup from '@/modules/common/components/ui-kit/color-picker-group.vue';
import { COLOR_NAMES } from '@/modules/common/constants';

@Component({
    components: {
        PageWrapper,
        DiLiteClusterHeader,
        DiLiteClusterActions,
        DiLiteHotelsList,
        ColorPickerGroup,
    },
})
export default class DiLiteClusterListPage extends Mixins(ClusterDiLiteOtelMixin) {
    beforeMount() {
        this.clusterService.hotels = null;
    }

    destroyed() {
        this.clusterService.resetLoading();
    }

    get clusterColorThresholds() {
        return this.clusterDiLiteService.colorThresholds;
    }

    set clusterColorThresholds(value: [number, number]) {
        this.clusterDiLiteService.colorThresholds = value;
    }

    get colorPickerProps() {
        return {
            colors: [COLOR_NAMES.GREEN, COLOR_NAMES.RED],
            min: 5,
            max: 30,
        };
    }
}
