
import { Component, Vue, Prop } from 'vue-property-decorator';
import ColorPicker, { Item } from '@/modules/common/components/ui-kit/color-picker.vue';
import { COLOR_NAMES } from '../../constants';

/**
 * Group from 2 connected color pickers
 * Middle box shows difference between values in first and second color picker
 * Could be used on rates and di lite pages to change thrasholds for colors
 * @prop {[COLOR_NAMES, COLOR_NAMES]} [colors=[COLOR_NAMES.RED, COLOR_NAMES.GREEN]]
 * @prop {[number, number]} [value=[5, 10]]
 * @prop {number} [min=0]
 * @prop {number} [max=100]
 * @prop {step} [step=5]
 */
@Component({
    components: { ColorPicker },
})
export default class ColorPickerGroup extends Vue {
    /**
     * Colors of first and last elemnt
     * Mid element is always yellow
     */
    @Prop({
        type: Array,
        default: [COLOR_NAMES.RED, COLOR_NAMES.GREEN],
    })
    colors!: [COLOR_NAMES, COLOR_NAMES];

    /**
     * Thresholds:
     *  -infinity - value[0] - first box
     *  value[0] - value[1] - yellow box
     *  value[1] - +infinity - third box
     */
    @Prop({
        type: Array,
        default: [5, 10],
    })
    value!: [number, number];

    /** Minimum value in dropdown menu */
    @Prop({
        type: Number,
        default: 0,
    })
    min!: number;

    /** Maximum value in dropdown menu */
    @Prop({
        type: Number,
        default: 100,
    })
    max!: number;

    /** Step for values in a dropdown menu */
    @Prop({
        type: Number,
        default: 5,
    })
    step!: number;

    get from() {
        return this.value[0];
    }

    set from(value) {
        this.$emit('input', [value, this.value[1]]);
    }

    get to() {
        return this.value[1];
    }

    set to(value) {
        this.$emit('input', [this.value[0], value]);
    }

    get thresholds() {
        const options = [];
        for (let i = this.min; i <= this.max; i += this.step) {
            options.push(i);
        }
        return options;
    }

    get fromItems(): Item[] {
        return this.thresholds
            .map((value: number): Item => ({ value, name: `<${value}%`, disabled: value > this.to }));
    }

    get toItems(): Item[] {
        return this.thresholds
            .map((value: number): Item => ({ value, name: `>${value}%`, disabled: value < this.from }));
    }

    format(value: number) {
        if (value > 0) {
            return `+${value}`;
        }
        return value;
    }
}
